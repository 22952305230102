.header{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,.12);
}

.pheader{
    margin-bottom: 0px;
    font-size: small;
}