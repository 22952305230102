@charset "UTF-8"; @font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXI3I6Li01BKofiOc5wtlZ2di8HDFwmdTQ3ig.ttf) format('truetype')
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxP.ttf) format('truetype')
}

@font-face {
  font-family: 'go3v2';
  src: url(../public/fuentes/go3v2.ttf) format('truetype')
}

@font-face {
  font-family: 'osake';
  src: url(../public/fuentes/Osake.ttf) format('truetype')
}
@font-face {
  font-family: 'bebasneue';
  src: url(../public/fuentes/BebasNeue-Regular.ttf) format('truetype')
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


li {
  list-style: none;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

ul::-webkit-scrollbar {
  width: 4px;               /* width of the entire scrollbar */
}

ul::-webkit-scrollbar-track {
  background: green;        /* color of the tracking area */
}

ul::-webkit-scrollbar-thumb {
  background-color: white;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 6px solid green;  /* creates padding around scroll thumb */
}
  .col-xs-12 {
    width: 100%;
  }
  .col-xs-3 {
    width: 25%;
  }
  
  .col-xs-9 {
    width: 75%;
  }
   .col-xs-6 {
    width: 50%;
  }


@media (min-width: 992px)
{.col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
}
.col-lg-3 {
  flex: 0 0 auto;
  width: 25%;
}}

@media (min-width: 992px){
.col-md-4 {
    width: 33.33333333%;
}
}

@media (min-width: 768px){
.col-sm-9 {
    width: 75%;
}}



@media (min-width: 576px)
{
  /* .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
} */
/* .col-sm-3 {
  flex: 0 0 auto;
  width: 25%;
} */
}

@media (min-width: 768px)
{.col-md-6 {
    flex: 0 0 auto;
    width: 50%;
}

.col-md-3 {
  flex: 0 0 auto;
  width: 25%;
}}

@media (min-width: 992px){
  .col-md-9 {
      width: 75%;
  }}

  @media (min-width: 768px){
.col-sm-8 {
    width: 66.66666667%;
}}
  @media (min-width: 992px){
.col-md-8 {
    width: 53%;
    /* width: 66.66666667%; */
}}

/* 


@media (min-width: 768px){
  .col-sm-6 {
      width: 50%;
  }
}  */

.desliz {
  margin: 0 auto;
  /* white-space:nowrap; */
  /* overflow:hidden; */
  box-sizing: border-box;
  animation: desliz 12s linear infinite;
  animation-play-state: paused;
  text-overflow: ellipsis
}

/* .desliz:hover {
  animation-play-state: running
} */

@keyframes desliz {
  0% {
      text-indent: 0
  }

  100% {
      text-indent: -100%
  }
}


.caja-producto-contenedor {
  height: 10em
}

@media (max-width: 300px) {
  .caja-producto-contenedor {
      padding:0 !important;
      margin: 0 !important
  }
}

@media (max-width: 500px) {
  .caja-producto-contenedor {
    height: auto;
    padding: .5em
  }
}

@media (min-width: 500px) and (max-width:700px) {
  .caja-producto-contenedor {
      height:6em
  }
}

@media (max-width: 767px) {
  .caja-producto-contenedor {
    border-bottom: 1px solid #999;
    padding: 1em;
    height: auto
  }
}

@media (min-width: 768px) and (max-width:1199px) {
  .caja-producto-contenedor {
      height: 9em;
  }
}

@media (min-width: 1110px) and (max-width:1200px) {
  .caja-producto-contenedor {
      height: 9em
  }
}




.containers {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px){
  .containers {
      width: 750px;
  }}

@media (min-width: 992px){
.containers {
    width: 970px;
}}

@media (min-width: 1200px){
  .containers {
      width: 1170px;
  }}
