.modalInfo{
    position:fixed;
    z-index: 999;
    top:0;
    left:0;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0,0,0,0.75);
    display: none;
    justify-content: center;
    align-items: center;
    /*transition: transform .5s;
    transform: translate(25%); */
}

.modalInfo.is-open{
    display: flex;

}

.modalInfo-container{
    border-radius: 7px;
    position: relative;
    /* padding: 1rem; */
    min-width: 320px;
    max-width: 370px;
    min-height: 200px;
    max-height: 750px;
    background-color: #fff;
    overflow-x: hidden;
    overflow-y: auto;
}

.modalInfo-close{
    position: absolute;
    top: 1rem;
    right: 1rem;
    border: none;
    background-color: #fff;
    padding: 0;
}

.modalProd{
    background-color: green;
    color: white;
}

.modalInfo-container::-webkit-scrollbar {
    width: 12px;               /* width of the entire scrollbar */
}

.modalInfo-container::-webkit-scrollbar-track {
    background: #fff;        /* color of the tracking area */
    border-radius: 7px;
}

.modalInfo-container::-webkit-scrollbar-thumb {
    background-color: green;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid #fff;  /* creates padding around scroll thumb */
}

#map {
    height: 300px;
}

div ul {
    list-style: none;
    padding-left: 0px;
}

@keyframes dropTop {
    0% {
      transform: translateX(125%);
      /* opacity: 0; */
    }
    100% {
      transform: translateX(25%);
      /* opacity: 1; */
    }
    
  }

.modalCarrito{
    position:fixed;
    /* background-color: rgba(0,0,0,0.75); */
    z-index: 999;
    top:0;
    left:0;
    right:0;
    bottom:0;
    width: 100%;
    min-height: 100vh;

    display:"flex";
    justify-content: flex-start;
    align-items: center;
}

.modalCarrito-container{
    position: relative;
    background-color: #fff;
    height: 100vh;
    width: 80%;
    transform: translate(25%);
    overflow-x: hidden;
    overflow-y: auto;
    animation: 0.5s linear 0s 1 normal none running dropTop;
}

.modalCarrito-close{
    position: absolute;
    top: 1rem;
    right: 1rem;
    border: none;
    background-color: black;
    padding: 0;
    /* border-radius: 20px; */
}

.flex-grow0{
    flex-grow: 0;
}

.flex-grow1{
    flex-grow: 1;
}

.flex-grow2{
    flex-grow: 2;
}

@media (max-width: 768px){
.titulo-producto {
    float: none;
}
}

.boton{
    width: 35px;
    height: 34px;
    border: none;
    border-radius: 17px;
    /* box-shadow: 0 10px 100px 0 rgba(0,0,0,.8); */
    color: white;
    background-color:#198754;
    margin: 0 0.2em;
}
.cantidad{
    width: 2.3em;
    height: 1.75em;
    display: inline-block;
    text-align: center;
    padding: 0;
    font-size: 1.2em;
    border-radius: 8px;
    border-color: #198754;
}

.botonVerde {
    width: 100%;
    height: 44px;
    border: none;
    border-radius: 8px;
    background-color: #00e35f;
    font-family: Nunito;
    color: #fff;
    text-transform: uppercase;
    margin-top: 1em
}

.botonCeleste {
    width: 100%;
    height: 44px;
    border: none;
    border-radius: 8px;
    background-color: #198754;
    font-family: Nunito;
    font-size: 1em;
    color: white;
    text-transform: uppercase;
    margin-top: 7px;
}

@media (min-width: 750px) {

    .modalInfo-container{
        border-radius: 7px;
        position: relative;
        /* padding: 1rem; */
        min-width: 600px;
        max-width: 600px;
        min-height: 200px;
        max-height: 750px;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: #fff;
    }

    .modalProd{
        background-color: green;
        color: white;
    }

    @keyframes dropTop {
        0% {
          transform: translateX(190%);
          /* opacity: 0; */
        }
        100% {
          transform: translateX(90%);
          /* opacity: 1; */
        }
        
      }
    
    .modalCarrito{
        position:fixed;
        /* background-color: rgba(0,0,0,0.75); */
        z-index: 999;
        top:0;
        left:0;
        right:0;
        bottom:0;
        width: 100%;
        min-height: 100vh;
    
        display:"flex";
        justify-content: flex-start;
        align-items: center;
    }
    
    .modalCarrito-container{
        position: relative;
        background-color: #fff;
        height: 100vh;
        width: 53%;
        transform: translate(90%);
        overflow-x: hidden;
        overflow-y: auto;
        animation: 0.5s linear 0s 1 normal none running dropTop;
    }

    .flex-grow0{
        flex-grow: 1;
    }
    
    .flex-grow1{
        flex-grow: 1;
    }
    
    .flex-grow2{
        flex-grow: 1;
    }
    
}

.modalCarrito-container::-webkit-scrollbar {
    width: 12px;               /* width of the entire scrollbar */
}

.modalCarrito-container::-webkit-scrollbar-track {
    background: #fff;        /* color of the tracking area */
    border-radius: 7px;
}

.modalCarrito-container::-webkit-scrollbar-thumb {
    background-color: green;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid #fff;  /* creates padding around scroll thumb */
}

.modalFinalizar{
    position:fixed;
    z-index: 999;
    top:0;
    left:0;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0,0,0,0.75);
    display: none;
    justify-content: center;
    max-height: 100%;
    /* align-items: center; */
    /*transition: transform .5s;
    transform: translate(25%); */
}

.modalFinalizar.is-open{
    display: flex;

}

.modalFinalizar-container{
    position: relative;
    width: 100%;
    min-height: 100vh;
    max-height: 750px;
    background-color: #fff;
    overflow-x: hidden;
    overflow-y: auto;
}

.modalFinalizar-close{
    position: absolute;
    top: 1rem;
    right: 1rem;
    border: none;
    background-color: #fff;
    padding: 0;
}

.modalFinalizar-container::-webkit-scrollbar {
    width: 12px;               /* width of the entire scrollbar */
}

.modalFinalizar-container::-webkit-scrollbar-track {
    background: #fff;        /* color of the tracking area */
    border-radius: 7px;
}

.modalFinalizar-container::-webkit-scrollbar-thumb {
    background-color: green;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid #fff;  /* creates padding around scroll thumb */
}